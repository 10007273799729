<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          class="py-0"
          cols="12"
        >
          <app-datetime-picker
            v-model="firstDate"
            :max-date="secondDate"
            :clearText="$vuetify.lang.t('$vuetify.actions.cancel')"
            :okText="$vuetify.lang.t('$vuetify.actions.accept')"
            :label="$vuetify.lang.t('$vuetify.date_text.init')"
          />
        </v-col>
        <v-col
          class="py-0"
          cols="12"
        >
          <app-datetime-picker
            v-model="secondDate"
            :min-date="firstDate"
            :clearText="$vuetify.lang.t('$vuetify.actions.cancel')"
            :okText="$vuetify.lang.t('$vuetify.actions.accept')"
            :label="$vuetify.lang.t('$vuetify.date_text.finish')"
          />
        </v-col>
        <v-col
          class="py-0"
          cols="12"
        >
          <v-select
            v-model="localShops"
            chips
            rounded
            solo
            :items="shops"
            :label="$vuetify.lang.t('$vuetify.menu.shop')"
            item-text="name"
            item-value="id"
            :loading="isShopLoading"
            :disabled="!!isShopLoading"
            required
            multiple
            :rules="formRule.country"
          />
        </v-col>
        <v-col
          class="py-0"
          cols="12"
        >
          <v-select
            v-model="groupBy"
            :items="groupByText"
            :label="$vuetify.lang.t('$vuetify.component.group_by')"
            item-text="text"
            item-value="value"
            required
            :rules="formRule.country"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" :disabled="!validSearch" @click="loadData">
        {{$vuetify.lang.t('$vuetify.actions.search')}}
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Range',
  data () {
    return {
      firstDate: new Date(new Date().setHours(0, 0, 0, 0)),
      secondDate: new Date(),
      localShops: [],
      groupBy: null,
      formRule: this.$rules
    }
  },
  computed: {
    ...mapState('shop', ['shops', 'isShopLoading']),
    validSearch () {
      return this.localShops.length > 0 && this.firstDate && this.secondDate && this.groupBy
    },
    groupByText () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.date_text.hours'),
          value: 'hours'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.date_text.days'),
          value: 'days'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.date_text.months'),
          value: 'month'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.date_text.years'),
          value: 'years'
        }
      ]
    }
  },
  async created () {
    await this.getShops().then(() => {
      this.shops.map((shop) => {
        this.localShops.push(shop.id)
      })
    })
  },
  methods: {
    ...mapActions('shop', ['getShops']),
    loadData () {
      this.$emit('updateFilter', this.firstDate, this.secondDate, this.localShops, this.groupBy)
    }
  }
}
</script>

<style scoped>

</style>
