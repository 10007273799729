<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="localShops"
            :items="shops"
            :label="$vuetify.lang.t('$vuetify.menu.shop')"
            item-text="name"
            item-value="id"
            :loading="isShopLoading"
            :disabled="!!isShopLoading"
            required
            multiple
            :rules="formRule.country"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            :value="months_text"
            :items="months_lang"
            @change="changeMonth"
            multiple
          />
        </v-col>
        <v-col cols="12">
          <v-date-picker
            v-model="months"
            type="month"
            multiple
          ></v-date-picker>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" :disabled="!validSearch" @click="loadData">
        {{$vuetify.lang.t('$vuetify.actions.search')}}
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Months',
  data () {
    return {
      months: [],
      years: [],
      months_text: [],
      months_lang: [],
      month_names: {
        es: [
          'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
        en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
          'October', 'November', 'December']
      },
      localShops: [],
      formRule: this.$rules
    }
  },
  watch: {
    'months_text.length': function () {
      this.changeMonth()
    },
    months: function () {
      this.months_lang = []
      this.months.forEach((v) => {
        this.months_lang.push({
          text: this.month_names[this.$vuetify.lang.current][parseInt(v.split('-')[1]) - 1] + '(' + v.split('-')[0] + ') ',
          value: v
        })
      })
      this.months_text = this.months_lang
    }
  },
  computed: {
    ...mapState('shop', ['shops', 'isShopLoading']),
    validSearch () {
      return this.localShops.length > 0 && this.months.length > 0
    }
  },
  async created () {
    await this.getShops().then(() => {
      this.shops.map((shop) => {
        this.localShops.push(shop.id)
      })
    })
  },
  methods: {
    ...mapActions('shop', ['getShops']),
    changeMonth () {
      if (this.months_text.length < this.months) {
        const result = []
        this.months_lang.forEach(v => {
          result.push(v.value)
        })
        this.months = this.months_lang
      }
    },
    loadData () {
      if (this.months.length > 1) {
        const self = this
        const localDates = this.months.sort((a, b) => new Date(b).getTime() - new Date(a).getTime()).reverse()
        this.dates = localDates
        this.$emit('updateFilterMonths', this.months, this.localShops)
      } else {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.actions.search'),
            text: this.$vuetify.lang.t('$vuetify.messages.warning_search_days'),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            )
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
