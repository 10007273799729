<template>

  <v-card>
    <v-card-text>
      <v-row><v-col cols="12">
        <v-select
          v-model="localShops"
          :items="shops"
          :label="$vuetify.lang.t('$vuetify.menu.shop')"
          item-text="name"
          item-value="id"
          :loading="isShopLoading"
          :disabled="!!isShopLoading"
          required
          multiple
          :rules="formRule.country"
        />
      </v-col>
        <v-col cols="12">
          <v-select
            :label="$vuetify.lang.t('$vuetify.date_text.years')"
            v-model="years"
            :items="yearsItem"
            multiple
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary"  :disabled="!validSearch" @click="loadData">
        {{$vuetify.lang.t('$vuetify.actions.search')}}
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Years',
  data () {
    return {
      years: [],
      localShops: [],
      formRule: this.$rules
    }
  },
  computed: {
    ...mapState('shop', ['shops', 'isShopLoading']),
    yearsItem () {
      const result = []
      let i = 2000
      while (i <= (new Date()).getFullYear()) {
        result.push(i)
        i++
      }
      return result
    },
    validSearch () {
      return this.localShops.length > 0 && this.years.length > 0
    }
  },
  async created () {
    await this.getShops().then(() => {
      this.shops.map((shop) => {
        this.localShops.push(shop.id)
      })
    })
  },
  methods: {
    ...mapActions('shop', ['getShops']),
    loadData () {
      if (this.years.length > 1) {
        const self = this
        const localDates = this.years.sort((a, b) => new Date(b).getTime() - new Date(a).getTime()).reverse()
        this.years = localDates
        this.$emit('updateFilterYears', this.years, this.localShops)
      } else {
        this.$Swal
          .fire({
            title: this.$vuetify.lang.t('$vuetify.actions.search'),
            text: this.$vuetify.lang.t('$vuetify.messages.warning_search_days'),
            icon: 'info',
            showCancelButton: false,
            confirmButtonText: this.$vuetify.lang.t(
              '$vuetify.actions.accept'
            )
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
