<template>
  <no-subcription v-if="!getSubscription"/>
  <v-container v-else>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-subtitle>
            {{ $vuetify.lang.t('$vuetify.component.filter') }}
          </v-card-subtitle>
          <v-card-text>
            <v-tabs
              v-model="currentItem"
            >
              <v-tab>
                {{ $vuetify.lang.t('$vuetify.component.range') }}
              </v-tab>
              <!--v-tab>
                {{ $vuetify.lang.t('$vuetify.date_text.hours') }}
              </v-tab-->
              <v-tab>
                {{ $vuetify.lang.t('$vuetify.date_text.days') }}
              </v-tab>
              <v-tab>
                {{ $vuetify.lang.t('$vuetify.date_text.months') }}
              </v-tab>
              <v-tab>
                {{ $vuetify.lang.t('$vuetify.date_text.years') }}
              </v-tab>
              <v-tabs-items v-model="currentItem">
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <range @updateFilter="filterRange"/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!--v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <hours/>
                    </v-card-text>
                  </v-card>
                </v-tab-item-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <days @updateFilterDays="filterDays"/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <months @updateFilterMonths="filterMonth"/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <years @updateFilterYears="filterYears"/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <highcharts :options="chartOptions"
                    v-if="this.profitableness.categories ? this.profitableness.categories.length > 0 : false"/>
        <p v-if="!(this.profitableness.categories ? this.profitableness.categories.length > 0 : false)"
           style="padding-right: 10px">{{ $vuetify.lang.t('$vuetify.messages.rentability_text_1') }}</p>
        <p v-if="!(this.profitableness.categories ? this.profitableness.categories.length > 0 : false)"
           style="padding-right: 10px">{{ $vuetify.lang.t('$vuetify.messages.rentability_text_2') }}</p>
      </v-col>
      <v-col cols="12" md="12" v-if="this.profitableness.categories ? this.profitableness.categories.length > 0 : false">
          <p style="margin-left: 15px">{{ $vuetify.lang.t('$vuetify.messages.resume_rentability_text_' + petition, paramsData) }}</p>
        <p style="margin-left: 15px">{{
            $vuetify.lang.t('$vuetify.messages.resume_rentability_text_1',
              [parseFloat(totalBills + totalExpenses + expenses + saleBills + othersExpenses + expensesCredit).toFixed(2)],
              [parseFloat(totalExpenses).toFixed(2)],
              [parseFloat(expenses).toFixed(2)],
              [parseFloat(saleBills).toFixed(2)],
              [parseFloat(othersExpenses).toFixed(2)],
              [parseFloat(expensesCredit).toFixed(2)],
              [getCurrency])
          }}</p>
        <p style="margin-left: 15px">{{
            $vuetify.lang.t('$vuetify.messages.resume_rentability_text_2',
              [parseFloat(incomes).toFixed(2)],
              [parseFloat(othersIncomes).toFixed(2)],
              [parseFloat(incomesCredit).toFixed(2)],
              [getCurrency])
          }}</p>
        <p style="margin-left: 15px">{{
            $vuetify.lang.t('$vuetify.messages.resume_rentability_text_3',
              [parseFloat(benefits).toFixed(2)],
              [getCurrency])
          }}</p>
        <p style="margin-left: 15px">{{
            $vuetify.lang.t('$vuetify.messages.resume_rentability_text_5')
          }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { Chart } from 'highcharts-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Hours from './filters/Hours'
import Months from './filters/Months'
import Years from './filters/Years'
import Days from './filters/Days'
import Range from './filters/Range'

export default {
  name: 'Profitableness',
  components: {
    Range,
    Days,
    Years,
    Months,
    // Hours,
    highcharts: Chart
  },
  data () {
    return {
      petition: '',
      paramsData: [],
      firstDate: null,
      secondDate: null,
      localShops: null,
      totalBills: 0,
      saleBills: 0,
      incomes: 0,
      incomesCredit: 0,
      totalExpenses: 0,
      othersIncomes: 0,
      expenses: 0,
      othersExpenses: 0,
      expensesCredit: 0,
      benefits: 0,
      groupBy: null,
      currentItem: 'tab-Web',
      chartOptions: {}
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('sale', ['profitableness']),
    ...mapState('auth', ['cantBox', 'company_subscription']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    },
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    }
  },
  created () {
    this.$store.state.sale.profitableness = []
  },
  methods: {
    ...mapActions('sale', ['getSaleFilterRange', 'getSaleFilterDays', 'getSaleFilterMonths', 'getSaleFilterYears']),
    async filterRange (firstDate, secondDate, localShops, groupBy) {
      await this.getSaleFilterRange({
        firstDate: firstDate, secondDate: secondDate, shops: localShops, groupBy: groupBy
      })
      this.firstDate = firstDate
      this.secondDate = secondDate
      this.localShops = localShops
      this.groupBy = groupBy
      this.petition = 'range'
      this.paramsData = [this.$moment(firstDate).format('YYYY-MM-DD H:m:s') + ' - ' + this.$moment(secondDate).format('YYYY-MM-DD H:m:s')]
      this.loadData()
    },
    async filterDays (dates, localShops) {
      await this.getSaleFilterDays({ shops: localShops, dates: dates })
      this.petition = 'days'
      this.paramsData = dates
      this.loadData()
    },
    async filterMonth (dates, localShops) {
      await this.getSaleFilterMonths({ shops: localShops, dates: dates })
      this.petition = 'months'
      this.paramsData = [dates]
      this.loadData()
    },
    async filterYears (dates, localShops) {
      await this.getSaleFilterYears({ shops: localShops, dates: dates })
      this.petition = 'years'
      this.paramsData = [dates]
      this.loadData()
    },
    loadData () {
      if (this.profitableness.categories ? this.profitableness.categories.length > 0 : false) {
        this.totalBills = 0
        this.totalExpenses = 0
        this.saleBills = 0
        this.incomes = 0
        this.othersIncomes = 0
        this.expenses = 0
        this.othersExpenses = 0
        this.expensesCredit = 0
        this.profitableness.series.forEach((v) => {
          // eslint-disable-next-line no-unused-expressions,no-sequences
          v.data.forEach((b) => {
            if (v.name === 'others_incomes') {
              this.othersIncomes += parseFloat(b)
            }
            if (v.name === 'income') {
              this.incomes += parseFloat(b)
            }
            if (v.name === 'sale_bills') {
              this.saleBills += parseFloat(b)
            }
            if (v.name === 'bills') {
              this.totalBills += parseFloat(b)
            }
            if (v.name === 'buy_bills') {
              this.totalExpenses += parseFloat(b)
            }
            if (v.name === 'expenses_buy') {
              this.expenses += parseFloat(b)
            }
            if (v.name === 'other_expenses') {
              this.othersExpenses += parseFloat(b)
            }
            if (v.name === 'expenses_credit') {
              this.expensesCredit += parseFloat(b)
            }
          })
          this.benefits = this.incomes + this.othersIncomes + this.incomesCredit - this.expenses - this.othersExpenses -
            this.saleBills - this.totalBills + this.totalExpenses
          v.name = this.$vuetify.lang.t('$vuetify.accounting_category.' + v.name)
        })
        const series = this.profitableness.series
        this.chartOptions = {
          title: {
            text: this.$vuetify.lang.t('$vuetify.menu.profitableness')
          },

          subtitle: {
            text: 'billingmoneyapp.com'
          },
          yAxis: {
            title: {
              text: this.getCurrency
            }
          },

          xAxis: {
            accessibility: {
              rangeDescription: 'Range: 2010 to 2017'
            },
            categories: this.profitableness.categories,
            crosshair: true
          },
          legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false
              }
              // pointStart: 2015
            }
          },
          series: series
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
